import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from '@synoptic/ui-kit/drawer/drawer.tsx';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { sidebarDrawerContent } from '../../routes/_app/sidebar/sidebar.css';

export type SidebarDrawerProps = PropsWithChildren<{
  trigger?: ReactNode;
}>;

export const SidebarDrawer: FC<SidebarDrawerProps> = ({
  trigger,
  children,
}) => {
  return (
    <Drawer direction={'left'}>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent
        className={sidebarDrawerContent}
        direction={'left'}
        withHandle={false}
        inversed
      >
        {children}
      </DrawerContent>
    </Drawer>
  );
};
