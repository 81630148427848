import { headerAvatar } from '@/components/page-header/drawer-avatar-trigger.css.ts';
import { SidebarDrawer } from '@/components/sidebar-drawer/sidebar-drawer.tsx';
import { ProfileMenu } from '@/routes/_app/sidebar/profile-menu.tsx';
import { MyAvatar } from '../my-avatar';

export const DrawerAvatarTrigger = () => {
  return (
    <SidebarDrawer
      trigger={
        <button style={{ all: 'unset' }} aria-label="Profile menu">
          <MyAvatar className={headerAvatar} />
        </button>
      }
    >
      <ProfileMenu opened />
    </SidebarDrawer>
  );
};
